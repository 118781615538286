import { useEffect } from "react";
import Head from "next/head";
import Image from "next/image";

import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import LandingHeader from "../components/LandingHeader";
import LandingFooter from "../components/LandingFooter";
import DemoForm from "../components/forms/Form";

import { getCookie } from "cookies-next";

import fonts from "../styles/FontInter.module.css";
import mainTheme from "../components/mainTheme";
import styles from "../styles/Home.module.css";

export default function TemplatePage({ utm }) {
  useEffect(() => {
    dataLayer.push({ event: "optimize.activate" });
  }, []);

  return (
    <ThemeProvider theme={mainTheme}>
      <Head>
        <title>Weichert and Elm Street Technology - Automated Marketing and Creative Design Services</title>
        <meta
          name="description"
          content="Weichert and Elm Street Technology - Automated Marketing and Creative Design Services"
        />
        <link rel="icon" href="/favicon.ico" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@weareelmstreet" />
        <meta name="twitter:creator" content="@weareelmstreet" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://elmstreet.com/weichert" />
        <meta property="og:title" content="Weichert and Elm Street Technology - Automated Marketing and Creative Design Services" />
        <meta
          property="og:description"
          content="Weichert and Elm Street Technology - Automated Marketing and Creative Design Services"
        />
        <meta property="og:image" content="https://elmstreet.com/og_images/elmstreetog.jpg" />
      </Head>
      <CssBaseline className={fonts} />

      <LandingHeader />

      <div
        style={{
          backgroundColor: "#000000",
          display: "block",
          fontFamily: "'Helvetica', 'Arial', sans-serif !important",
        }}
      >
        <Container
          maxWidth="lg"
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "55px",
            paddingBottom: "75px",
          }}
        >
          <div style={{ marginTop: "55px" }}>
            <Grid container spacing={8}>
            <Grid item md={12} sm={12} style={{ textAlign:"left" }}>
                <div>
                  <span style={{marginRight:"25px"}}><Image
                    src="/weichert/Weichert-logo.png"
                    width="200px"
                    height="56px"
                    
                    alt=""
                  /></span>

<Image
                    src="/weichert/est-logo.png"
                    width="250px"
                    height="58px"
                    alt=""
                  />  
                </div>
              </Grid>
              <Grid item md={6} sm={12} style={{ paddingBottom: "20px" }}>
                <Typography variant="h2" component="h1" style={{ color: "#ffffff" }}>AUTOMATED
                    MARKETING <br />
                     <span style={{ color: "#fff321" }}>&amp; CREATIVE DESIGN
                    SERVICES</span>
                </Typography>
                <br />
                
                <span style={{ color: "#fff321", fontSize:"18px" }}><strong>MARKETING DONE FOR YOU<br />
                  BY ELM STREET TECHNOLOGY</strong></span>
                  <br /><br />
                <div style={{ color: "#ffffff", paddingBottom:"25px" }}>
                Elm Street Technology is your Weichert approved supplier of done-for-you marketing, professionally-designed email and newsletter templates, creative design and website services, and ongoing educational opportunities. We provide a suite of solutions to Weichert associates and managers based on your needs and goals. Explore the tools and resources available below.
                </div>
              </Grid>
              <Grid item md={6} sm={12} style={{ marginBottom: "-6px" }}>
                <div>
                  <Image
                    src="/weichert/hero.png"
                    width="1000px"
                    height="899px"
                    alt=""
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>







      <div
        style={{
          backgroundColor: "#ffffff",
          display: "block",
          fontFamily: "'Helvetica', 'Arial', sans-serif !important",
        }}
      >
        <Container
          maxWidth="lg"
          style={{
            paddingLeft: "25px",
            paddingRight: "25px",
            paddingTop: "85px",
            paddingBottom: "55px",
          }}
        >
          <div style={{ marginTop: "75px" }}>
            <Grid container spacing={8} style={{marginLeft:"0px", width:"100%"}}>
          
              <Grid item md={6} sm={12} style={{ padding: "55px", backgroundColor:"#e0e0e0", textAlign:"center" }}>

                <Typography variant="h5" component="h5" style={{ color: "#000000" }}>done-for-you<br />
                    digital MARKETING
                </Typography>

                
                <div style={{ color: "#000000", paddingTop:"15px", paddingBottom:"15px" }}>- Repeat/Referral-Focused Content to Maintain Your Sphere of Influence.<br />
- Associate-Branded Email Newsletters.<br />
- Curated Social Media Posts.<br />
- All Automated to Stay Top of Mind.
</div>
                <div style={{paddingBottom:"15px"}}>
                  <Image
                    src="/weichert/oe-samples.png"
                    width="1150px"
                    height="902px"
                    alt=""
                  />
                </div>

                <div style={{ paddingBottom:"25px", color: "#000000" }}>
                
               

<div style={{ backgroundColor: "#ffffff", marginTop:"25px" }}>
              <Container
                maxWidth="lg"
                style={{
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  paddingTop: "45px",
                  paddingBottom: "45px",
                }}
              >
                <Grid container>
                    <DemoForm
                      buttonText="SUBMIT"
                      buttonColor="#fff321"
                      successMsg={<div style={{textAlign:"center"}}><strong>Thank you! One of our coaches will be reaching out to you shortly.</strong><br/></div>}
                      listId="148003"
                      formName="es2_weichert_oe"
                      fieldList={["first_name", "last_name", "email", "phone", "company"]}
                    />
                    </Grid>
              </Container>
              
            </div>
            <div style={{paddingTop: "30px"}}>
             <strong>IF YOU ALREADY HAVE<br />
            AN ACCOUNT, ACCESS IT HERE.</strong>
              <br /><br />
            <Button variant="contained"  href="https://app.outboundengine.com/" target="_blank" style={{color:"#000000", backgroundColor:"#fff321"}}>
                    access my account
                  </Button>
                  </div>
                </div>
              </Grid>

              <Grid item md={6} sm={12} style={{ padding: "55px", backgroundColor:"#8e8e8e", textAlign:"center" }}>
                <Typography variant="h5" component="h5" style={{ color: "#ffffff" }}>automated direct<br />
                mail newsletters
                </Typography>
                
                <div style={{ color: "#ffffff", paddingTop:"15px", paddingBottom:"15px" }}>- Client &amp; Prospect Versions Available.<br />
- Personalized with Your Contact's Name.<br />
- Option to customize with<br />
Your Own Branding.<br />
- Automatically Sent to Your Database.<br />
</div>
                <div style={{paddingBottom:"15px"}}>
                  <Image
                    src="/weichert/morris-samples.png"
                    width="1150px"
                    height="902px"
                    alt=""
                  />
                </div>
                <div style={{ paddingBottom:"25px", color: "#ffffff" }}>
                
               
<div style={{ backgroundColor: "#ffffff", color: "#000000", marginTop:"25px" }}>
              <Container
                maxWidth="lg"
                style={{
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  paddingTop: "45px",
                  paddingBottom: "45px",
                }}
              >
                <Grid container>
                    <DemoForm
                      buttonText="SUBMIT"
                      buttonColor="#fff321"
                      successMsg={<div style={{textAlign:"center"}}><strong>Thank you! One of our coaches will be reaching out to you shortly.</strong><br/></div>}
                      listId="148003"
                      formName="es2_weichert_morris"
                      fieldList={["first_name", "last_name", "email", "phone", "company"]}
                    />
                    </Grid>
              </Container>
            </div>
           
            <div style={{paddingTop: "30px"}}> <strong>IF YOU ALREADY HAVE<br />
            AN ACCOUNT, ACCESS IT HERE.</strong>
              <br /><br />
              <Button variant="contained"  href="https://member.morrismarketinggroup.com/login/agent-login.aspx" target="_blank" style={{color:"#000000", backgroundColor:"#fff321"}}>
                    access my account
                  </Button></div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>






      <div
        style={{
          backgroundColor: "#ffffff",
          display: "block",
          fontFamily: "'Helvetica', 'Arial', sans-serif !important",
        }}
      >
        <Container
          maxWidth="lg"
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "75px",
          }}
        >
          <div style={{ marginTop: "25px" }}>
            <Grid container spacing={8}>
        
              <Grid item md={12} sm={12} style={{ paddingBottom: "20px", textAlign:"center" }}>
                <Typography variant="h4" component="h4" style={{ color: "#000000" }}>creative design services &amp; websites
                </Typography>
               
                <div style={{ color: "#000000", paddingTop:"15px", paddingBottom:"15px" }}>- Easy Drag-&amp;-Drop Website Builder. (Weichert Themes Available)<br />
                - All-In-One Analytics Dashboard.<br />
                - Optional Design Services to Build Your Custom Brand.<br />
                - A-la-Carte Graphic Design Services.</div>

                <div>
                  <Image
                    src="/weichert/weichert-samples.png"
                    width="2500px"
                    height="1407px"
                    alt=""
                  />
                </div>
              
              

              </Grid>
            
            </Grid>
          </div>

          <div style={{ marginBottom: "48px", paddingTop:"15px" }}>
          <Grid container spacing={8} justifyContent="center">
            <Grid item md={8}>
            <DemoForm
              buttonText="Submit"
              buttonColor="#fff321"
              successMsg={<div style={{textAlign:"center"}}><strong>Thank you! One of our coaches will be reaching out to you shortly.</strong><br/></div>}
              formName="es2_weichert_w4r" listId="148009"
              fieldList={["first_name", "last_name", "email", "phone", "company"]}
            />
            </Grid>
          </Grid>
        </div>
        <div style={{textAlign:"center"}}>
        <strong>IF YOU ALREADY HAVE<br />
            AN ACCOUNT, ACCESS IT HERE.</strong>
              <br /><br />
<Button variant="contained"  href="https://elmstreetrr.com/login/" target="_blank" style={{color:"#000000", backgroundColor:"#fff321"}}>
                    access my account
                  </Button>
                  </div>
        </Container>
      </div>


     
      


      <div
        style={{
          backgroundImage: "url('/weichert/b&l-banner.jpg')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          display: "block",
          fontFamily: "'Helvetica', 'Arial', sans-serif !important",
        }}
      >
        <Container
          maxWidth="lg"
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "35px",
            paddingBottom: "45px",
          }}
        >
          <div style={{ marginTop: "45px" }}>
            <Grid container spacing={8}>
             
              <Grid item md={12} sm={12} style={{textAlign:"center"}}>
                <div>
                  <Image
                    src="/weichert/bl.png"
                    width="380px"
                    height="242px"
                    alt="Elm Street Academy Brunch &amp; Learn"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>


      <div
        style={{
          backgroundColor: "#fff321",
          display: "block",
          fontFamily: "'Helvetica', 'Arial', sans-serif !important",
        }}
      >
        <Container
          maxWidth="lg"
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "45px",
            paddingBottom: "45px",
          }}
        >
          <div>
            <Grid container spacing={8}>
             
              <Grid item md={12} sm={12} style={{textAlign:"center"}}>
              <Typography variant="h4" component="h4" style={{ color: "#000000" }}>SCHEDULE A BRUNCH &amp; LEARN FOR YOUR OFFICE
                </Typography>

                <strong style={{color:"#000000"}}>Bring the #1 Educational Training Series for Real Estate Professionals to Your Office!</strong>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>

      <div
        style={{
          backgroundColor: "#f0f0f0",
          display: "block",
          fontFamily: "'Helvetica', 'Arial', sans-serif !important",
        }}
      >
        <Container
          maxWidth="lg"
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "55px",
            paddingBottom: "45px",
          }}
        >
          <div style={{ marginTop: "55px", paddingBottom:"30px" }}>
            <Grid container spacing={8}>
          
              <Grid item md={6} sm={12} style={{ paddingBottom: "20px" }}>
               
              <Typography variant="h4" component="h4" style={{ color: "#000000" }}>WE TEACH TOPICS THAT<br />
              HELP AGENTS THRIVE!
                </Typography><br />
                <div style={{ color: "#000000", paddingBottom:"25px" }}>
                <Typography variant="h5" component="h5" style={{ color: "#000000" }}>TOPICS INCLUDE:
                </Typography>
 Social Media<br />
 Lead Generation<br />
 Lead Nurturing<br />
 Repeat/Referral<br />
 Marketing Strategies<br />
 Lead Conversion<br />
 Technology Training<br />
&amp; MUCH MORE!
                </div>
              </Grid>
              <Grid item md={6} sm={12} style={{ marginBottom: "-6px" }}>
              <Typography variant="h4" component="h4" style={{ color: "#000000" }}>HOW IT WORKS:</Typography><br />

              <div style={{ color: "#000000", }}>We partner with you to bring an educational experience to your office that inspires creativity and
motivates!<br /><br />
You provide the space &amp; which topic you would like us to cover with your organization. We provide
action-oriented education, awesome food, tasty drinks &amp; a fun experience that will have attendees
asking for more!</div>
<br />
<Typography variant="h5" component="h5" style={{ color: "#000000",}}>THE BEST PART?<br />
ALL EDUCATIONAL EXPERIENCES<br />
ARE FREE TO HOST!</Typography>
              </Grid>
            </Grid>
          </div>
       
        </Container>
      </div>



      <div
      style={{
        backgroundColor: "#fff321",
        display: "block",
        fontFamily: "'Helvetica', 'Arial', sans-serif !important",
      }}>

<Container
          maxWidth="lg"
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "55px",
            paddingBottom: "75px",
          }}
        >


<div style={{color:"#000000", textAlign:"center", paddingTop:"20px"}}><strong>Ready to have a Brunch &amp; Learn experience at your office?<br />
Click below and submit your request!</strong><br /><br />

<Button variant="contained"  href="mailto:MarketingRequest@weichertrealtors.net" target="_blank" style={{color:"#fff321", backgroundColor:"#000000"}}>
                    contact us today
                  </Button>
                  </div>

          </Container>

      </div>

      <div
        style={{
          backgroundColor: "#000000",
          display: "block",
          fontFamily: "'Helvetica', 'Arial', sans-serif !important",
        }}
      >
        <Container
          maxWidth="lg"
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "55px",
            paddingBottom: "75px",
          }}
        >
          <div style={{ marginTop: "55px", marginBottom:"30px" }}>
            <Grid container spacing={8}>
            <Grid item md={12} sm={12} style={{ textAlign:"center" }}>
                <div>
                  <span style={{marginRight:"25px"}}><Image
                    src="/weichert/Weichert-logo.png"
                    width="200px"
                    height="56px"
                    
                    alt=""
                  /></span>

<Image
                    src="/weichert/est-logo.png"
                    width="250px"
                    height="58px"
                    alt=""
                  />  
                </div>
              </Grid>
             
            
            </Grid>
          </div>
        </Container>
      </div>

    </ThemeProvider>
  );
}

export const getServerSideProps = ({ req, res }) => {
  let cookies = getCookie("elmlead", { req, res });
  if (cookies) {
    return { props: { utm: cookies } };
  }
  return { props: {} };
};
